require('./frontend.scss');
require('./frontend-resources.js');

const $ = (window.jQuery = require('jquery'));
const ajaxForm = require('./ajax-form');
const { autoRecaptcha } = require('./recaptcha');

function gaTriggers() {
	gtag('event', 'click', {
		event_category: $(this).data('ga-category'),
		event_label: $(this).data('ga-label'),
	});
}

$(function () {

	const url = top.location.pathname.substr(1);

	autoRecaptcha(
		'.recaptcha-field',
		'6Leq9swZAAAAAFPCJO7Je8J_4DmyMV8I-VdwSSHs',
		url
	);
	ajaxForm('form.ajax');
	$('.js-track').click(gaTriggers);
});
