const $ = (window.jQuery = require('jquery'));
const { refreshRecaptcha } = require('./recaptcha');

function ajaxForm(sel) {
    $(sel).each(function() {
        var $form = $(this);
        var $fields = $form.find('input, textarea');
        var $captcha = $form.find('[name=recaptcha]');
        var url = $form.attr('action');

        $form.submit(function(e) {
            var post = $form.serialize();
            e.preventDefault();

            $form.find('.error').removeClass('error');
            $form.find('.feedback').remove();
            $.post(url, post, function(data, status) {
                refreshRecaptcha();

                if (status === 'success') {
                    if (data.fields) {
                        var $first = null;

                        for (var i = 0; i < data.fields.length; i++) {
                            var $field = $form.find(
                                '[data-elem="' + data.fields[i] + '"]'
                            );

                            if (!$field.length) {
                                $field = $fields.filter(
                                    '[name="' + data.fields[i] + '"]'
                                );
                            }

                            if ($field.length) {
                                $field.addClass('error');

                                if (!$first) {
                                    $first = $field;
                                }
                            }
                        }

                        if ($first) {
                            $first.focus();
                        }
                    }

                    if (data.message) {
                        var $feedback = $('<div class="feedback"></div>')
                            .text(data.message)
                            .addClass(
                                data.success
                                    ? 'feedback-success'
                                    : 'feedback-error'
                            );

                        $form.prepend($feedback);
                    }

                    if (data.success) {
                        $form[0].reset();
                        if (window.gtag) {
                            window.gtag('event', 'submit', {
                                event_category: 'Form',
                                event_label: url,
                            });
                        }
                    }
                }
            });
        });
    });
}

module.exports = ajaxForm;
